<template>
	<Header />
	<div id="app">
		<div class="layout-container">
			<div class="layout layout--actions">
				<aside></aside>
				<div class="main-wrapper">
					<main class="main">
						<slot />
					</main>
				</div>
			</div>
		</div>
		<Footer no-start />
	</div>
</template>
